<template>
  <div id="app">
    <!-- Меню -->
    <Menu
      v-if="isMenuShowing()"
      :locked="isMenuLocked()" 
      :items="menulist"
    />
   
    <!-- Информация о текущем регионе и нозологии -->
    <div class="current-set">
      <fade-transition :duration="{enter: 300, leave: 400}" mode="out-in">
        <CurrentReg v-if="isRegShowing()" :reg="regName" :ref=""refFl/>
      </fade-transition>
      <fade-transition :duration="{enter: 300, leave: 400}" mode="out-in">
        <CurrentNos v-if="isNosShowing()" :nosologyName="nosologyName"/>
      </fade-transition>
    </div>

    <!-- Экраны -->
    <fade-transition :duration="{enter: 300, leave: 400}" mode="out-in">
      <router-view :week="weeks" :programflag="flag" :weight="weight" :count="patnum" :curNosology="nosology" class="view"/>
    </fade-transition>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import CurrentReg from '@/components/CurrentReg.vue'
import CurrentNos from '@/components/CurrentNos.vue'
import { FadeTransition } from 'vue2-transitions'
import dataStore from '@/views/data';

import { eventBus } from '@/main'

export default {
  components:{
    Menu,
    FadeTransition,
    CurrentReg,
    CurrentNos
  },
  data:function(){
      return {
      nosology:6,
      patnum:1,
      weight:75,
      weeks:52,
      flag:false,
      state:1, 
      region:1,
      refFl:0,

      curRegion:'Республика Карелия',
      curNosology:'Меланома',
      menulist:[
        {
          title:'Выбор<br> региона',
          link:'/fo_select',
        },
        {
          title:'Выбор<br> нозологии',
          link:'/nosology',
        },
        {
          title:'Стоимость препаратов',
          link:'/info',
        },
        {
          title:'Выбор схем терапии',
          link:'/therapy',
        },
        {
          title:'Расходы на 1 пациента',
          link:'/singlecalc',
        },
        {
          title:'Расходы на группу пациентов',
          link:'/groupcalc',
        }
      ]
    }
  },

  computed:{
    regName(){
      
      let name, ref;

      if(dataStore.selectedRegs[this.region - 1]) {
        name = dataStore.selectedRegs[this.region - 1].regName;
      }
      else {
        dataStore.currLawDoc = '';
        name = 'Не выбран';
      }
      ref = this.refFl;
      return name;
    },
    nosologyName(){
      return dataStore.nosology[this.nosology];
    }
  },


  methods:{
    isMenuShowing(){
      return this.$router.history.current["name"] !== "Home";
    },
    isMenuLocked(){
      return (this.$router.history.current["name"] !== "Region")&&
             (this.$router.history.current["name"] !== "State");
    },
    isRegShowing(){
      return (this.$router.history.current["name"] !== "Home")&&
             (this.$router.history.current["name"] !== "Region")&&
             (this.$router.history.current["name"] !== "State");
    },
    isNosShowing(){
      return (this.$router.history.current["name"] !== "Home")&&
             (this.$router.history.current["name"] !== "Region")&&
             (this.$router.history.current["name"] !== "State")&&
             (this.$router.history.current["name"] !== "Nosology");
    }
  },
  mounted(){
    this.refFl = !this.refF
    eventBus.$on('week-change', payLoad => {
        this.weeks = payLoad;
    });
    eventBus.$on('flag-change', payLoad => {
        this.flag = payLoad;
    });
    eventBus.$on('fo-change', payLoad => {
        this.fo = payLoad;
    });
    eventBus.$on('region-change', payLoad => {
        this.region = payLoad;
        this.refFl = !this.refFl;
    });
    eventBus.$on('nosology-change', payLoad => {
        this.nosology = payLoad;
    });
    eventBus.$on('weight-change', payLoad => {
        this.weight = payLoad;
    });
    eventBus.$on('patnum-change', payLoad => {
        this.patnum = payLoad;
    });
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto');

html, body{
  background-color:#B7D5E0;
  padding:0;
  margin:0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  font-family: 'Roboto', sans-serif;
  color: #2c3e50;
  width:1024px;
  height:728px;
  display:block;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 10px
}

h1{
  margin: 0px;
  font-size:24px;
  text-align:center;
  color:#03417A;
}
button:active, button:focus, input:active, input:focus {
  outline: none !important;
}
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0 !important;
}
.none-selectable{
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none; 
}
.view{
  padding-bottom: 20px;
}
</style>
