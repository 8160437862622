import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/fo_select',
    name: 'State',
    component: () => import(/* webpackChunkName: "about" */ '../views/State.vue')
  },
  {
    path: '/region_select',
    name: 'Region',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Region.vue')
  },
  {
    path: '/nosology',
    name: 'Nosology',
    component: () => import(/* webpackChunkName: "nosology" */ '../views/Nosology.vue')
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import(/* webpackChunkName: "info" */ '../views/Info.vue')
  },
  {
    path: '/drug-info',
    name: 'DrugInfo',
    component: () => import(/* webpackChunkName: "info" */ '../views/DrugInfo.vue')
  },
  {
    path: '/therapy',
    name: 'Therapy',
    component: () => import(/* webpackChunkName: "therapy" */ '../views/Therapy.vue')
  },
  {
    path: '/singlecalc',
    name: 'SingleCalc',
    component: () => import(/* webpackChunkName: "singlecalc" */ '../views/SingleCalc.vue')
  },
  {
    path: '/groupcalc',
    name: 'GroupCalc',
    component: () => import(/* webpackChunkName: "groupcalc" */ '../views/GroupCalc.vue')
  },
  {
    path: '/printing',
    name: 'Printing',
    component: () => import(/* webpackChunkName: "printing" */ '../views/Printing.vue')
  },
  {
    path: '*',
    redirect: { name: 'Home' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
