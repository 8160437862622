<template>
  <div class="home">
    <div class="bg" :style="bg" />
    <div class="bg2" />
	  <div class="cont">
      <img src="@/assets/img/logo.png" class="logo"/>
      <h1 class="title">КАЛЬКУЛЯТОР ДЛЯ АНАЛИЗА ЗАТРАТ<br>на комбинированную  терапию двумя иммуноонкологическими<br>препаратами</h1>
      <div class="enter">
        <p>Вся информация, размещенная на данном веб-сайте, предназначена исключительно для специалистов здравоохранения — медицинских и фармацевтических работников, и может быть использована с целью оптимизации бюджета на комбинированную  терапию двумя иммуноонкологическими препаратами. Информация на данном сайте не должна быть использована для диагностики и лечения, в частности, для подбора дозы препаратов.<p>
        <p class="confirm"><b>Пожалуйста, подтвердите, что вы являетесь <br> специалистом здравоохранения</b></p>
        <a href="https://www.bms.com/ru"
            class="home-btn no">
            Нет
        </a>
        <router-link
            class="home-btn yes"
            to="/fo_select">
            Да
        </router-link>
      </div>
      <p class="subcom">
        <a class="outlink" href="https://io-calculator.ru/archive/">
          Расчет для монотерапии<br><br>
        </a>
      Интернет-сайт создан по заказу ООО «Бристол-Майерс Сквибб».<br>
      Сведения, представленные на настоящем Интернет-сайте, носят публичный характер и служат исключительно информационным целям.</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {

  },
  data: function(){
    return {
      bg: {
        backgroundImage: `url(${require('@/assets/img/img.jpg')})`
      }
    }
  }
}
</script>
<style scoped>
.title{
  padding:30px 0 50px 0;
}
.logo{
  width: 235px;
  display: block;
  padding: 10px 0 0 0;
  margin: auto;
}
.subcom{
  display: block;
  width: 560px;
  margin: auto;
  padding: 40px 0;
  font-size: 10px;
}
.outlink {
  font-size: 16px;
}
.home-btn{
  margin: auto;
  margin-top: 15px;
  display: inline-block;
  width: 49%;
  box-sizing:border-box;

  text-decoration: none;
  color: #fff;
  font-family: 'Roboto';
  text-align: center;
  padding: 12px;
}
.no{
  margin-right:5px;
  background-color: #787572;
}
.yes{
  margin-left:5px;
  background-color: #0084BD;
}
.confirm{
  text-align:center;
  padding: 10px 0 0 0;
}
.bg{
  opacity: 0.4;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
}
.bg2{
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(0, transparent, #B7D5E0);
  z-index: 5;
}
.cont{
  position:relative;
  z-index:10;
}
.enter{
  width: 500px;
  text-align: justify;
  background: linear-gradient(0, #fff, #ffffff26);
  padding: 10px 30px;
  margin: auto;
}
</style>
