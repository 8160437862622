
const schemas = {

	1: {
		name: 'Ниволумаб + ипилимумаб',
		code: 'sh0604',
		info: 'Ниволумаб 1 мг/кг в 1-й день + ипилимумаб 3 мг/кг в 1-й день; цикл 21 день',
		prepData: [
	 	{ 
	 		prepNum: [1,2],
	 		dose: 1,
	 		mode: 'm',
	 		timeLine: [1,0,0, 1,0,0, 1,0,0, 1,0,0],
	 		color:'#03417A',
			gcolor:'#505556' 
	 	},
	 	{ 
	 		prepNum: [3],
	 		dose: 3,
	 		mode: 'm',
	 		timeLine: [1,0,0, 1,0,0, 1,0,0, 1,0,0],
	 		color:'#00C013',
			gcolor:'#acb3b5' 
	 	},
	 	{ 
	 		prepNum: [1,2],
	 		dose: 480,
	 		mode: 'a',
	 		timeLine: [0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0],
	 		color:'#0084BD',
			gcolor: '#8d9699' 
	 	}
		]
	}, 

	2: {
		name: 'Ниволумаб + ипилимумаб',
		code: 'sh0709',
		info: 'Ниволумаб 3 мг/кг в 1-й день + ипилимумаб 1 мг/кг в 1-й день; цикл 21 день',
		prepData: [
	 	{ 
	 		prepNum: [1,2],
	 		dose: 3,
	 		mode: 'm',
	 		timeLine: [1,0,0, 1,0,0, 1,0,0, 1,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0], 
	 		color:'#03417A',
			gcolor:'#505556' 
	 	},
	 	{ 
	 		prepNum: [3],
	 		dose: 1,
	 		mode: 'm',
	 		timeLine: [1,0,0, 1,0,0, 1,0,0, 1,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0],
	 		color:'#00C013',
			gcolor:'#acb3b5'
	 	},
	 	{ 
	 		prepNum: [1,2],
	 		dose: 480,
	 		mode: 'a',
	 		timeLine: [0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0,0, 1,0,0],
	 		color:'#0084BD',
			gcolor:'#8d9699'
	 	}
		]
	}, 

	3: {
		name: 'Ниволумаб + ипилимумаб + паклитаксел + карбоплатин',
		code: 'sh1063',
		info: 'Ниволумаб 360 мг 1 раз в 3 недели + ипилимумаб 1 мг/кг 1 раз в 6 недель + 2 цикла химиотерапии 1 раз в 3 недели – паклитаксел 200 мг/м2 + карбоплатин AUC 6 в/в капельно',
		prepData: [
	 		{ 
 			prepNum: [1,2],
 			dose: 360,
 			mode: 'a',
	 		timeLine: [1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0],
	 		color:'#03417A',
			gcolor:'#505556'
	 		},
	 		{ 
 			prepNum: [3],
 			dose: 1,
 			mode: 'm',
	 		timeLine: [1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0],
	 		color:'#00C013',
			gcolor:'#acb3b5'  
	 		},
	 		{ 
 			prepNum: [4],
 			dose: 200,
 			mode: 's',
	 		timeLine: [1,0,0, 1,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0], 
	 		color:'#939393', 
			gcolor:'#939393'
	 		},
	 		{ 
 			prepNum: [5],
 			dose: 130,
 			mode: 'a',
	 		timeLine: [1,0,0, 1,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0], 
	 		color:'#666563',
			gcolor:'#666563' 
	 		}
		]
	}, 
	4: {
		name: 'Ниволумаб + ипилимумаб + пеметрексед + карбоплатин',
		code: 'sh1061',
		info: '(Ниволумаб 360 мг 1 раз в 3 недели + ипилимумаб 1 мг/кг 1 раз в 6 недель + 2 цикла химиотерапии 1 раз в 3 недели  – пеметрексед 500 мг/м2 + карбоплатин AUC 5 или 6 в/в капельно',
		prepData: [
	 		{ 
 			prepNum: [1,2],
 			dose: 360,
 			mode: 'a',
	 		timeLine: [1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0],
	 		color:'#03417A',
			gcolor:'#505556' 
	 		},
	 		{ 
 			prepNum: [3],
 			dose: 1,
 			mode: 'm',
	 		timeLine: [1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0],
	 		color:'#00C013',
			gcolor:'#acb3b5'  
	 		},
	 		{ 
 			prepNum: [6],
 			dose: 500,
 			mode: 's',
	 		timeLine: [1,0,0, 1,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0], 
	 		color:'#939393',
			gcolor:'#939393' 
	 		},
	 		{ 
 			prepNum: [5],
 			dose: 130,
 			mode: 'a',
	 		timeLine: [1,0,0, 1,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0], 
	 		color:'#666563',
			gcolor:'#666563' 
	 		}
		]
	}, 
	5: {
		name: 'Ниволумаб + ипилимумаб + пеметрексед + цисплатин',
		code: 'sh1062',
		info: '(Ниволумаб 360 мг 1 раз в 3 недели + ипилимумаб 1 мг/кг 1 раз в 6 недель + 2 цикла химиотерапии 1 раз в 3 недели  – пеметрексед 500 мг/м2 + цисплатин 75 мг/м2 в/в капельно',
		prepData: [
	 		{ 
 			prepNum: [1,2],
 			dose: 360,
 			mode: 'a',
	 		timeLine: [1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0, 1,0,0],
	 		color:'#03417A',
			gcolor:'#505556' 
	 		},
	 		{ 
 			prepNum: [3],
 			dose: 1,
 			mode: 'm',
	 		timeLine: [1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0, 1,0,0, 0,0,0],
	 		color:'#00C013',
			gcolor:'#acb3b5'  
	 		},
	 		{ 
 			prepNum: [6],
 			dose: 500,
 			mode: 's',
	 		timeLine: [1,0,0, 1,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0], 
	 		color:'#939393',
			gcolor:'#939393' 
	 		},
	 		{ 
 			prepNum: [7],
 			dose: 75,
 			mode: 's',
	 		timeLine: [1,0,0, 1,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0], 
	 		color:'#666563',
			gcolor:'#666563'
	 		}
		]
	} 

}

export default schemas;