
import drugList from './drugStore';
import initList from './initPrice';
import schemas from './schemas';

const Store = {

    dblClickEvent: 'dblclick',
    clickEvent: 'click',
    bodyArea: 1.79, // Average body area
    currMethod: 1, // Current selected schema of therapy
    currWeeks: 52, // Selected number of weeks for calculation
    currWeeksSave: 52,
 
    nosology: [
        'Меланома',                 // 0
        'ГЦК',                      // 1
        'ПКР',                      // 2
        'КРР',                      // 3
        'Плоскоклеточный НМРЛ Л1',  // 4
        'Неплоскоклеточный НМРЛ Л1', // 5
        'Вне зависимости от показания' // 6
    ],

    pathMethods: {
        0: [1,0,0,0,0], // Меланома
        1: [1,0,0,0,0], // ГЦК
        2: [0,1,0,0,0], // ПКР
        3: [0,1,0,0,0], // КРР
        4: [0,0,1,0,0], // Плоскоклеточный НМРЛ Л1
        5: [0,0,0,1,1], // Неплоскоклеточный НМРЛ Л1
        6: [1,1,1,1,1]  // Вне зависимости от показания
    },

    rlModel: // 
        [100.00, 100.00, 100.00, 100.00, 100.00, 100.00,    // 1
        87.43, 87.43, 87.43, 87.43, 87.43, 87.43,           // 7       
        72.91, 72.91, 72.91, 72.91, 72.91, 72.91,           // 13
        61.17, 61.17, 61.17, 61.17, 61.17, 61.17,           // 19
        49.72, 49.72, 49.72, 49.72, 49.72, 49.72,           // 25
        41.90, 41.90, 41.90, 41.90, 41.90, 41.90,           // 31
        35.47, 35.47, 35.47, 35.47, 35.47, 35.47,           // 37
        32.12, 32.12, 32.12, 32.12, 32.12, 32.12,           // 43
        29.89, 29.89, 29.89, 29.89, 29.89, 29.89,           // 49
        25.42, 25.42, 25.42, 25.42, 25.42, 25.42],          // 55


 //                0  1  2  3  4  5  6  7  8  9  10  11  12  13  14  15
    nivolIndic:  [1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1,  1,  1,  1,  0,  0], // Indications for Nivolumab
    pembroIndic: [1, 1, 1, 1, 0, 0, 1, 1, 1, 1, 1,  1,  0,  1,  1,  1], // Indications for Pembrolizumab
    atezoIndic:  [0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 1,  0,  0,  0,  0,  0], // Indications for Atezolizumab

// With VAT !!!!!!
    'nivol40txt':'34 183,85',
    'nivol100txt':'85 460,49',
    'pembro100txt':'170 475,25',
    'atezotxt':'237 523,10',
//    'atezotxt':'0,00',

	priceSet: {
	    'nivol40':34183.85,
	    'nivol100':85460.49,
        'pembro100':170475.25,
        'atezo':237523.10
//        'atezo':0.00
	},

	defaultSet: {
	    'nivol40':34183.85,
	    'nivol100':85460.49,
	    'pembro100':170475.25,
        'atezo':237523.10
//        'atezo':0.00
	},

	indic: [
        'Меланома',                     // 0
        'Адъювантная меланома',         // 1
        'НМРЛ (1 линия)',               // 2
        'НМРЛ (2 линия)',               // 3
        'Мелкоклеточный рак легкого',   // 4
        'Почечно-клеточный рак',        // 5
        'Лимфома Ходжкина',             // 6
        'Рак головы и шеи',             // 7
        'Уротелиальный рак (1 линия)',  // 8
        'Вне зависимости от показания', // 9
        'Уротелиальный рак (2 линия)',  // 10
        'Гепатоцеллюлярный рак',        // 11
        'Колоректальный рак',           // 12
        'Рак желудка (после 2-й и более линий)',       // 13
        'Опухоли с микросателлитной нестабильностью',  // 14
        'Рак шейки матки'               // 15
	],

	indicCap: [
        'Меланома',                     // 0
        'Адъювантная меланома',         // 1
        'НМРЛ (1 линия)',               // 2
        'НМРЛ (2 линия)',               // 3
        'Мелкоклеточный рак легкого',   // 4
        'Почечно-клеточный рак',        // 5
        'Лимфома Ходжкина',             // 6
        'Рак головы и шеи',             // 7
        'Уротелиальный рак (1 линия)',  // 8
        'Вне зависимости от показания', // 9
        'Уротелиальный рак (2 линия)',  // 10
        'Гепатоцеллюлярный рак',        // 11
        'Колоректальный рак',           // 12
        'Рак желудка (после 2-й и более линий)',       // 13
        'Опухоли с микросателлитной нестабильностью',  // 14
        'Рак шейки матки'               // 15
	],

	currIndic: 6,
    flEditLineOpen: 0,
    lastEditValue: 0,
    flEnableFixed: true,
    flIndicOpen: 0,	



	calcTarget:1000, // Начиная с какой экономии нужно оптимизировать набор флаконов по цене

    nivol40:34754.62,
    nivol100:86887.36,
    pembro100:170475.25,
    atezo:237523.10,
//    atezo:0.0,
    nivolDose:3.0, // mg/kg
    pembroDose:2.0, // mg/kg
    pembroSpecial:200, // mg/infusion
    atezoDose:1200, // mg/infusion
    nivolFreq:6, // infusions per 3 months
    pembroFreq:4, // infusions per 3 months
    atezoFreq:4, // infusions per 3 months
    nivol40Quant:0, // quantity of flasks
    nivol100Quant:0, // quantity of flasks
    pembro100Quant:0, // quantity of flasks
    atezoQuant:0, // quantity of flasks
    nivolVolume:0, // temporary storage for nivol dose 
    pembroVolume:0, // temporary storage for pembro dose
    atezoVolume:0, // temporary storage for atezo dose
    prtNivolVolume:0, // value for printing of nivol dose 
    prtPembroVolume:0, // value for printing of pembro dose
    prtAtezoVolume:0, // value for printing of pembro dose
    nivol40Price:0, // Price of 40ml flasks for one infusion
    nivol100Price:0, // Price of 100ml flasks for one infusion
    nivolPrice:0, // Price of one infusion
    pembroPrice:0, // Price of one infusion
    atezoPrice:0, // Price of one infusion
    nivolCurs:0, // Price of selected curs
    pembroCurs:0,
    atezoCurs:0,
    nivol40Curs:0,
    nivol100Curs:0,
    nivol40CursQuant:0, // Number of flasks for curs
    nivol100CursQuant:0, // Number of flasks for curs
    pembroCursQuant:0,
    atezoCursQuant:0,
    nivolUtil:0, // Cost of utilisation for one infusion
    pembroUtil:0,
    atezoUtil:0,
    nivolCursUtil:0,
    pembroCursUtil:0,
    atezoCursUtil:0,
    flSpecial:1, // Special mode of therapy for Pembro 200mg/infusion
    flNivolActive:1, // Rendering of Nivol data is allowed for selected indication
    flPembroActive:1, // Rendering of Pembro data is allowed for selected indication
    flAtezoActive:1, // Rendering of Atezo data is allowed for selected indication

	hslider:0,
    patWeight:75,
    scr2Weight:75,
    minWeight:30,
    leftBlock:0, // All slider is available but left end will be equal to 30kg
    rightEnd:100, // Maximal right position of slider in kg 
    currLenNumber:0,
    lastLenNumber:-1,
    onePatNivolMultipl:6, // Multiplicator to calculate number of infusions for curs
    onePatPembroMultipl:4, // Multiplicator to calculate number of infusions for curs
    onePatAtezoMultipl:4, // Multiplicator to calculate number of infusions for curs

//var curseLength = [3, 6, 9, 12]; // In months   
	curseLength:[12, 24, 36, 52], // In weeks   

	totPats: 1,
//    oldNofPats: 4,
    oldNofPats: 1,
    nivolSumm: 0, 
    nivolSumm40: 0, // Price 
    nivolSumm100: 0, // Price
    pembroSumm: 0, 
    atezoSumm: 0, 
    nivol40Summ: 0, // Flasks
    nivol100Summ: 0, // Flasks 
    pembro100Summ: 0,
    atezo1200Summ: 0,
    patAverWeight: 75,
    patFixedWeight: 75,
//    groupData: [65, 73, 75, 86],
//    groupData: [75, 75, 75, 75],
    groupData: [75],
    currLawDoc: 'Постановление Губернатора Белгородской области от 19.02.2010 № 11',
    currMult: 10.0,
    vat: 10.0,
    flInterest: false,
    flInterestSave: false,



// Engine vars

	tablePos: 0,
    oldTablePos: 0, // Table scroll position 
    tableSlider: 0,
    flFixedMode: true, // Mode for 240 mg infusion of nivol
    calcMode: 'aver',
    dispMode: 'price',
    tableOverflow: 0,

    regList: {// Update from 06-12-2019
    0:{
        'regName':'Центральный Федеральный округ',
        'zone':'',
        'regDescr':'',
        'inter':0,
        'lawdoc':''
    },

    1:{
        'regName':'Белгородская область',
        'zone':'',
        'regDescr':'',
        'inter':5,
        'lawdoc':'Постановление Губернатора Белгородской области от 19.02.2010 № 11'
    },

    2:{
        'regName':'Брянская область',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Приказ Управления государственного регулирования тарифов Брянской области  от 26.10.2017 г.№ 30/ 8-л'
    },

    3:{
        'regName':'Владимирская область',
        'zone':'',
        'regDescr':'',
        'inter':11,
        'lawdoc':'Постановление Губернатора Владимирской области от 26.02.2010 №167, от 05.02.2015 № 61'
    },

    4:{
        'regName':'Воронежская область',
        'zone':'',
        'regDescr':'',
        'inter':7,
        'lawdoc':'Приказ Управления по государственному регулированию тарифов Воронежской области от 26.02.2010 № 6/2,в (редакции приказа от 24.03.2017 № 12/1)'
    },

    5:{
        'regName':'Ивановская область',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Постановление РСТ Ивановской области от 24.02.2010 № 259-л/1, постановление РСТ Ивановской обл. от 18.03.2010 № 265-л/1, Постановление Департамента энергетики и тарифов Ивановской обл. от 18.12.2015 № 60-л/1.'
    },

    6:{
        'regName':'Калужская область',
        'zone':'',
        'regDescr':'',
        'inter':7.5,
        'lawdoc':'Приказ Министерства тарифного регулирования Калужской области от  14.12.2015 № 530-РК (в ред. приказа Министерства конкурентной политики Калужской обл. от 13.01.2020 № 5-РК)'
    },

    7:{
        'regName':'Костромская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Департамента государственного регулирования цен и тарифов Костромской области от 26.12.2014 № 14/506, (в ред.от 30.03.2018)'
    },

    8:{
        'regName':'Курская область',
        'zone':'',
        'regDescr':'',
        'inter':8,
        'lawdoc':'Постановление Администрации  Курской области от 27.08.2010 № 377-па'
    },

    9:{
        'regName':'Липецкая область',
        'zone':'',
        'regDescr':'',
        'inter':7.2,
        'lawdoc':'Приказ  Управления по ценам и контролю в сфере размещения государственных заказов Липецкой области от 25.02.2010 № 5'
    },

    10:{
        'regName':'г.Москва',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Правительства г.Москвы от 24.02.2010 № 163-пп'
    },

    11:{
        'regName':'Московская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Правительства Московской области от 26.02.2010 № 100/8 '
    },

    12:{
        'regName':'Орловская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Приказ Управления по тарифам Орловской области от 18.02.2010 № 88-т, (01.02.2016 № 76-т; в редакция от 23.01.2017)'
    },

    13:{
        'regName':'Рязанская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Главного Управления "Региональная энергетическая комиссия" Рязанской области от 20.12.2011 № 488'
    },

    14:{
        'regName':'Смоленская область',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Постановление Администрации Смоленской области от 22.12.2010г. № 809 (в ред. от 21.02.2018 № 98)'
    },

    15:{
        'regName':'Тамбовская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление администрации Тамбовской области от 27.08.2010 № 1026 (в ред.от 26.11.2015)'
    },

    16:{
        'regName':'Тверская область',
        'zone':'',
        'regDescr':'',
        'inter':8.5,
        'lawdoc':'Приказ РЭК Тверской области от 29.11.2010 № 545-нп'
    },

    17:{
        'regName':'Тульская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Правительства Тульской области от 26.02.2010 № 179, от 01.07.2013  № 313'
    },

    18:{
        'regName':'Ярославская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Правительства Ярославской области  от 26.02.2010  № 111-п'
    },

    19:{
        'regName':'Северо-Западный Федеральный округ',
        'zone':'',
        'regDescr':'',
        'inter':0,
        'lawdoc':''
    },


    20:{
        'regName':'Республика Карелия',
        'zone':'',
        'regDescr':'',
        'inter':15,
        'lawdoc':'Постановление Правительства Республики Карелия от 26.02.2010 № 29-П; Постановление Правительства Республики Карелия от 14.09.2015 № 295-П.'
    },

    21:{
        'regName':'Республика Коми',
        'zone':'1 зона',
        'regDescr':'1 зона - МО ГО "Сыктывкар",  МО МР "Койгородский", МО МР "Прилузский", МО МР"Сысольский",  МО МР"Княжпогостский",  МО МР"Корткеросский",  МО МР "Усть-Куломский",  МО МР "Сыктывдинский",  МО МР "Усть-Вымский", МО МР "Троицко-Печорский",  МО МР "Удорский", МО ГО "Ухта", МО МР "Вуктыл", МО МР "Сосногорск"',
        'inter':12,
        'lawdoc':'Приказ Службы Республики Коми по тарифам от 04.10.2010 № 65/1, от 10.12.2010 № 94/4'
    },

    22:{
        'regName':'Республика Коми',
        'zone':'2 зона',
        'regDescr':'2 зона - МО МР "Печора", МО МР "Ижемский", МО МР "Усть-Цилемский", МО ГО "Инта", МО ГО "Усинск", МО ГО "Воркута"',
        'inter':14,
        'lawdoc':'Приказ Службы Республики Коми по тарифам от 04.10.2010 № 65/1, от 10.12.2010 № 94/4'
    },

    23:{
        'regName':'Архангельская область',
        'zone':'1 группа районов',
        'regDescr':'1 группа районов - районы с ограниченными сроками завоза грузов - Верхнетоемский, Ленский, Лешуконский, Мезенский, Пинежский, Приморский и Шенкурский и территория архипелага Новая Земля.',
        'inter':14,
        'lawdoc':'Постановление Агентства по тарифам и ценам Архангельской области  19.07.2013  № 44-п/1'
    },

    24:{
        'regName':'Архангельская область',
        'zone':'2 группа районов',
        'regDescr':'2 группа районов - все остальные',
        'inter':11,
        'lawdoc':'Постановление   Агентства по тарифам и ценам Архангельской области  19.07.2013  № 44-п/1'
    },

    25:{
        'regName':'Ненецкий автономный округ',
        'zone':'',
        'regDescr':'',
        'inter':8,
        'lawdoc':'Приказ Комитета по гос.регулированию цен (тарифов) Ненецкого А.О. от 25.02.2010 № 14 (в редакции Приказа от 22.02.2019 № 6)'
    },

    26:{
        'regName':'Вологодская область',
        'zone':'',
        'regDescr':'',
        'inter':11.5,
        'lawdoc':'Постановление Правительства Вологодской области  от 24.06.2015 № 216'
    },

    27:{
        'regName':'Калининградская область',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Постановление Правительства Калининградской области от 26.02.2010 № 68 (в ред. Постановлений Правительства Калининградской области от 10.05.2011 № 329, от 25.03.2015 № 147, от 08.12.2015 № 695)'
    },

    28:{
        'regName':'Ленинградская область',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Приказ Комитета по тарифам и ценовой политике (Лен РТК) от 01.03.2010 № 30-п, Приказ Комитета по тарифам и ценовой политике (Лен РТК) от 01.04.2010 № 46-п, от 19.09.2011 № 100-п'
    },

    29:{
        'regName':'Мурманская область',
        'zone':'',
        'regDescr':'',
        'inter':20,
        'lawdoc':'Постановление Правительства Мурманской области от 26.02.2010 № 76-ПП (в ред Постановления Правительства Мурманской обл. от 10.12.2015 № 568-ПП)'
    },

    30:{
        'regName':'Новгородская область',
        'zone':'',
        'regDescr':'',
        'inter':6,
        'lawdoc':'Постановление  Администрации Новгородской области от 25.02.2010 № 77, от 20.04.2010 № 174, от 02.10.2012 № 589, постановление Правительства Новгородской области от 21.01.2014 № 7'
    },

    31:{
        'regName':'Псковская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Администрации Псковской области от 10.12.2015 № 555'
    },

    32:{
        'regName':'г.Санкт-Петербург',
        'zone':'',
        'regDescr':'',
        'inter':14,
        'lawdoc':'Постановление Правительства г.Санкт-Петербурга от 06.09.2010 № 1190'
    },

    33:{
        'regName':'Южный Федеральный округ',
        'zone':'',
        'regDescr':'',
        'inter':0,
        'lawdoc':''
    },


    34:{
        'regName':'Республика Адыгея',
        'zone':'',
        'regDescr':'',
        'inter':15,
        'lawdoc':'Приказ Управления государственного регулирования цен и тарифов Республики Адыгея  от 31.08.2010 № 26-п/2010 лек, от  23.09.2010 № 40п/2010-лек, от 27.12.2010 № 108-п/2010-лек, 17.09.2014 № 96-п, от 14.10.2014№ 108-п'
    },

    35:{
        'regName':'Астраханская область',
        'zone':'',
        'regDescr':'',
        'inter':11.75,
        'lawdoc':'Постановления Правительства Астраханской области  от 26.02.2010 № 67-п и от 27.02.2010 № 68-п,  от 03.08.2010 № 342-П'
    },

    36:{
        'regName':'Волгоградская область',
        'zone':'',
        'regDescr':'',
        'inter':10.36,
        'lawdoc':' Приказ комитета тарифного регулирования Волгоградской области от 06.11.2019 № 35/1'
    },

    37:{
        'regName':'Республика Калмыкия',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Приказ РСТ Республики Калмыкия от 15.12.2015 № 93п/лп'
    },

    38:{
        'regName':'Краснодарский край',
        'zone':'',
        'regDescr':'',
        'inter':9,
        'lawdoc':'Приказ РЭК-Департамента цен и тарифов Краснодарского края от 23.10.2019 № 5/2019-м.'
    },

    39:{
        'regName':'Ростовская область',
        'zone':'',
        'regDescr':'',
        'inter':8.8,
        'lawdoc':'Постановление РСТ Ростовской области от 25.02.2010 № 2-1, от 29.01.2015 № 5/1'
    },

//!! Update
    40:{
        'regName':'Республика Крым',
        'zone':'',
        'regDescr':'',
        'inter':9,
        'lawdoc':'Приказ Государственного Комитета по ценам и тарифам Республики Крым от 06.02.2020 № 5/1'
    },

    41:{
        'regName':'г. Севастополь',
        'zone':'',
        'regDescr':'',
        'inter':15,
        'lawdoc':'Постановление Управления по тарифам города Севастополя от 26.10.2015 № 15/47-м'
    },

    42:{
        'regName':'Северо-Кавказский федеральный округ',
        'zone':'',
        'regDescr':'',
        'inter':0,
        'lawdoc':''
    },


    43:{
        'regName':'Республика Дагестан',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Республиканской Службы по тарифам Республики Дагестан от 31.10.2014 № 43'
    },

    44:{
        'regName':'Чеченская Республика',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Решение Правления Государственного Комитета цен и тарифов Чеченской Республики от 31.10.2014 № 50-у. '
    },

    45:{
        'regName':'Республика Ингушетия',
        'zone':'',
        'regDescr':'',
        'inter':14,
        'lawdoc':'Постановление  Правительства Республики Ингушетия от  19.01.2011 № 5'
    },

    46:{
        'regName':'Кабардино-Балкарская Республика',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Госуд.Комитета КБР по тарифам от 25.02.2010 № 7'
    },

    47:{
        'regName':'Карачаево-Черкесская  Республика',
        'zone':'',
        'regDescr':'',
        'inter':11,
        'lawdoc':'Постановление Главного Управления КЧР по тарифам и ценам от 26.02.2010 № 9, от 13.09.2010 № 77, от 23.04.2015 № 61'
    },

    48:{
        'regName':'Республика Северная Осетия-Алания',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Постановление Правительства Республики Северная Осетия-Алания от 26.12.2014 №471'
    },

    49:{
        'regName':'Ставропольский край',
        'zone':'',
        'regDescr':'',
        'inter':10.7,
        'lawdoc':'Постановление Региональной Тарифной Комиссии Ставропольского края от 03.04.2014 № 22'
    },

    50:{
        'regName':'Приволжский Федеральный округ',
        'zone':'',
        'regDescr':'',
        'inter':0,
        'lawdoc':''
    },


    51:{
        'regName':'Республика Башкортостан',
        'zone':'',
        'regDescr':'',
        'inter':9,
        'lawdoc':'Постановление Правительства Республики Башкортостан по тарифам от 04.02.2016 № 20'
    },

    52:{
        'regName':'Республика Марий-Эл',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Приказ Минздрава Республики Марий Эл от 20.12.2019 № 2634'
    },

    53:{
        'regName':'Республика Мордовия',
        'zone':'',
        'regDescr':'',
        'inter':9,
        'lawdoc':'Постановление Правительства Республики Мордовия от 24.01.2011 № 12'
    },

    54:{
        'regName':'Республика Татарстан',
        'zone':'',
        'regDescr':'',
        'inter':11,
        'lawdoc':'Постановление Комитета Татарстана по тарифам от 19.02.2010 № 8-1/соц, от 21.08.2010 № 8-3/соц'
    },

    55:{
        'regName':'Удмуртская Республика',
        'zone':'',
        'regDescr':'',
        'inter':9,
        'lawdoc':'Постановление РЭК Удмуртской Республики от 09 ноября 2012 № 14/8'
    },

    56:{
        'regName':'Чувашская Республика',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Кабинета Министров Чувашской Республики от 9 декабря 2010 № 411'
    },

    57:{
        'regName':'Кировская область',
        'zone':'',
        'regDescr':'',
        'inter':9,
        'lawdoc':'Решение правления РСТ Кировской области от 13.09.2016 № 33/3-нпс-2016.'
    },

    58:{
        'regName':'Нижегородская область',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Постановление Правительства Нижегородской области от 22.03.2011 № 193'
    },

    59:{
        'regName':'Оренбургская область',
        'zone':'',
        'regDescr':'',
        'inter':11,
        'lawdoc':'Постановление Правительства Оренбургской области от 26.04.2011 № 258-п'
    },

    60:{
        'regName':'Пензенская область',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Приказ Управления по регулированию тарифов, энергосбережению и размещению госзаказа Пензенской области от 25.02.2010 № 8, приказ Управления от 02.04.2010 № 15, приказ от 20.12.2010 № 41'
    },

    61:{
        'regName':'Пермский край',
        'zone':'',
        'regDescr':'',
        'inter':16,
        'lawdoc':'Постановление РЭК Пермского края от 26.02.2010 № 3, от 28.04.2011 № 9'
    },

    62:{
        'regName':'Самарская область',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Постановления Правительства Самарской области от 17.02.2010 № 40, от 21.04.2010 № 144'
    },

    63:{
        'regName':'Саратовская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Правительства Саратовской области от 27.02.2010 № 65-П'
    },

    64:{
        'regName':'Ульяновская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Приказ Министерства экономики Ульяновской области от 27.02.2010 № 06-21'
    },

    65:{
        'regName':'Уральский Федеральный округ',
        'zone':'',
        'regDescr':'',
        'inter':0,
        'lawdoc':''
    },


    66:{
        'regName':'Курганская область',
        'zone':'',
        'regDescr':'',
        'inter':14,
        'lawdoc':'Постановление  Департамента государственного регулирования цен и тарифов Курганской области от 9 января 2010г № 1-1, постановление  Департамента государственного регулирования цен и тарифов Курганской области от 19 марта 2010 № 8-1, от 12.08.2010 № 25-3, 18.11.2010 № 40-31, от 07.07.2011 № 28-5, от 07.06.2012 № 22-1'
    },

    67:{
        'regName':'Свердловская область',
        'zone':'',
        'regDescr':'',
        'inter':14,
        'lawdoc':'Постановление РЭК Свердловской области от 01.12.2010 № 149-ПК'
    },

    68:{
        'regName':'Тюменская область',
        'zone':'',
        'regDescr':'',
        'inter':14.3,
        'lawdoc':'Постановление Правительства Тюменской области  от 27.02.2010 № 43-п, от 15.03.2010  № 74-п'
    },

    69:{
        'regName':'Ханты-Мансийский автономный округ',
        'zone':'',
        'regDescr':'',
        'inter':13,
        'lawdoc':'Постановление Правительства Ханты-Мансийского а.о.- Югры от 24.02.2010 № 67-п "Об установлении предельных размеров оптовых надбавок и предельных размеров розничных надбавок на лекарственные препараты, включенные в перечень ЖНВЛП" (с изменениями от  21.08.2015 № 275-п), (с изменениями от 22.12.2016 № 558-п)'
    },

    70:{
        'regName':'Ямало-Ненецкий автономный округ',
        'zone':'1 зона',
        'regDescr':'1 зона - г.Салехард, г.Лабытнанги, г.Муравленко, г.Новый Уренгой, г. Ноябрьск, г.Губкинский, г.Надым, г.Тарко-Сале',
        'inter':12.1,
        'lawdoc':'Приказ Департамента тарифной политики, энергетики и жилищно-коммунального комплекса Ямало-Ненецкого автономного округа 29.12.2015 № 448-т'
    },

    71:{
        'regName':'Ямало-Ненецкий автономный округ',
        'zone':'2 зона',
        'regDescr':'2 зона - прочие населенные пункты Ямало-Ненецкого автономного округа',
        'inter':12.1,
        'lawdoc':'Приказ Департамента тарифной политики, энергетики и жилищно-коммунального комплекса Ямало-Ненецкого автономного округа 29.12.2015 № 448-т'
    },

    72:{
        'regName':'Челябинская область',
        'zone':'',
        'regDescr':'',
        'inter':20,
        'lawdoc':'Постановление Правительства Челябинской области от 26.02.2010 № 51-п (от 18.04.2012 № 151-п) '
    },

    73:{
        'regName':'Сибирский Федеральный округ',
        'zone':'',
        'regDescr':'',
        'inter':0,
        'lawdoc':''
    },


    74:{
        'regName':'Республика Алтай',
        'zone':'1 зона',
        'regDescr':'1 зона - муниципальные образования, расположенные на территории районов, приравненных к районам Крайнего Севера (Кош-Агачский район, Улаганский район)',
        'inter':8,
        'lawdoc':'Постановление Комитета по тарифам  Республики Алтай от 27.02.2010 № 2, от 05.04.2010 № 4/1,  Приказ Комитета по тарифам Республики Алтай от 30.12.2013 N 33/6'
    },

    75:{
        'regName':'Республика Алтай',
        'zone':'2 зона',
        'regDescr':'2 зона - прочие муниципальные образования',
        'inter':8,
        'lawdoc':'Постановление Комитета по тарифам  Республики Алтай от 27.02.2010 № 2, от 05.04.2010 № 4/1,  Приказ Комитета по тарифам Республики Алтай от 30.12.2013 N 33/6'
    },

    76:{
        'regName':'Республика Бурятия',
        'zone':'1 зона',
        'regDescr':'1 зона - городской округ "город Улан-Удэ"',
        'inter':13,
        'lawdoc':'Приказ РСТ Республики Бурятия от 25.02.2010 № 4/10, от 25.03.2010 № 4/14, от 13.04.2010 № 4/18, от 16.06.2011 № 4/5, от 28.06.2012 № 4/4'
    },

    77:{
        'regName':'Республика Бурятия',
        'zone':'4 зона',
        'regDescr':'2 зона - муниципальные районы, за исключением районов, приравненных к районам Крайнего Севера,  городского округа "город Северобайкальск"',
        'inter':13,
        'lawdoc':'Приказ РСТ Республики Бурятия от 25.02.2010 № 4/10, от 25.03.2010 № 4/14, от 13.04.2010 № 4/18, от 16.06.2011 № 4/5, от 28.06.2012 № 4/4'
    },

    78:{
        'regName':'Республика Бурятия',
        'zone':'3 зона',
        'regDescr':'3 зона - муниципальные районы, приравненные к районам  Крайнего Севера, городской округ "город Северобайкальск"',
        'inter':13,
        'lawdoc':'Приказ РСТ Республики Бурятия от 25.02.2010 № 4/10, от 25.03.2010 № 4/14, от 13.04.2010 № 4/18, от 16.06.2011 № 4/5, от 28.06.2012 № 4/4'
    },

    79:{
        'regName':'Республика Тыва',
        'zone':'1 зона',
        'regDescr':'1 зона - г.Кызыл',
        'inter':15,
        'lawdoc':'Постановление Правительства Республика Тыва  от  09.03.2010  №56'
    },

    80:{
        'regName':'Республика Тыва',
        'zone':'2 зона',
        'regDescr':'2 зона - г.Ак-Довурак, кожууны республики',
        'inter':15,
        'lawdoc':'Постановление Правительства Республика Тыва  от  09.03.2010  №56'
    },

    81:{
        'regName':'Республика Хакасия',
        'zone':'',
        'regDescr':'',
        'inter':15,
        'lawdoc':'Постановление Правительства Республики Хакасия от 24.02.2010 № 81, от 22.10.2010   № 536, от 23.10.2012 № 710'
    },

    82:{
        'regName':'Алтайский край',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Решение Управления Алтайского края по государственному регулированию цен и тарифов от 06.11.2019 № 322'
    },

    83:{
        'regName':'Красноярский край',
        'zone':'1 зона',
        'regDescr':'1 зона - районы Крайнего Севера',
        'inter':11,
        'lawdoc':'Постановление Правительства Красноярского  края от  22.11.2011 № 705-п'
    },

    84:{
        'regName':'Красноярский край',
        'zone':'2 зона',
        'regDescr':'2 зона - местности, приравненные к районам Крайнего Севера',
        'inter':11,
        'lawdoc':'Постановление Правительства Красноярского  края от  22.11.2011 № 705-п'
    },

    85:{
        'regName':'Красноярский край',
        'zone':'3 зона',
        'regDescr':'3 зона - города и районы края, за исключением районов Крайнего Севера и приравненных к ним местностей.',
        'inter':11,
        'lawdoc':'Постановление Правительства Красноярского  края от  22.11.2011 № 705-п'
    },

    86:{
        'regName':'Иркутская область',
        'zone':'1 зона',
        'regDescr':'1 зона - МО Иркутской области (за исключением вошедших во 2 и 3 зону)',
        'inter':13,
        'lawdoc':'Приказ Службы по тарифам Иркутской области от 22.10.2010 № 88-спр'
    },

    87:{
        'regName':'Иркутская область',
        'zone':'2 зона',
        'regDescr':'2 зона - Усть-Кутское МО, МО "Нижнеилимский район",МО "Усть-Илимский район", МО "Братский район", МО "Жигаловский район", МО Иркутской области "Казачинско-Ленский район", МО "Качугский район",  МО города Братска,  МО город Усть-Илимск, Верхнегутарское МО, Нерхинское МО, Тофаларское МО, МО "Кырма"',
        'inter':13,
        'lawdoc':'Приказ Службы по тарифам Иркутской области от 22.10.2010 № 88-спр'
    },

    88:{
        'regName':'Иркутская область',
        'zone':'3 зона',
        'regDescr':'3 зона -  МО города Бодайбо и района, МО "Катангский район", МО "Киренский район", МО Мамско-Чуйского района',
        'inter':13,
        'lawdoc':'Приказ Службы по тарифам Иркутской области от 22.10.2010 № 88-спр'
    },

    89:{
        'regName':'Кемеровская область',
        'zone':'',
        'regDescr':'',
        'inter':10,
        'lawdoc':'Постановление Департамента цен и тарифов Кемеровской области от 11.11.2010  № 38'
    },

    90:{
        'regName':'Новосибирская область',
        'zone':'',
        'regDescr':'',
        'inter':12,
        'lawdoc':'Постановление Администрации Новосибирской области от 17.02.2010 № 57-па'
    },

    91:{
        'regName':'Омская область',
        'zone':'',
        'regDescr':'',
        'inter':13,
        'lawdoc':'Приказ РЭК Омской области от 27.02.2010 № 21/9'
    },

    92:{
        'regName':'Томская область',
        'zone':'1 зона',
        'regDescr':'1 зона - муниципальные образования, расположенные на территории районов, приравненных к районам Крайнего Севера',
        'inter':11,
        'lawdoc':'Постановление Администрации Томской области от 28.07.2010 № 150а, от 18.07.2012 № 282а'
    },

    93:{
        'regName':'Томская область',
        'zone':'2 зона',
        'regDescr':'2 зона - прочие муниципальные образования',
        'inter':11,
        'lawdoc':'Постановление Администрации Томской области от 28.07.2010 № 150а, от 18.07.2012 № 282а'
    },

    94:{
        'regName':'Забайкальский край',
        'zone':'1 зона',
        'regDescr':'1 зона - город Чита и город Краснокаменск',
        'inter':20,
        'lawdoc':'Постановление Правительства Забайкальского края от 25.05.2018 № 304-НПА'
    },

    95:{
        'regName':'Забайкальский край',
        'zone':'2 зона',
        'regDescr':'2 зона - территория Забайкальского края за исключением территорий города Читы и город Краснокаменска',
        'inter':20,
        'lawdoc':'Постановление Правительства Забайкальского края от 25.05.2018 № 304-НПА'
    },

    96:{
        'regName':'Дальневосточный Федеральный округ',
        'zone':'',
        'regDescr':'',
        'inter':0,
        'lawdoc':''
    },


    97:{
        'regName':'Республика Саха (Якутия)',
        'zone':'1 зона',
        'regDescr':'1 зона - город Якутск, город Нерюнгри',
        'inter':16,
        'lawdoc':'Приказ Государственного Комитета по ценовой политике Республики Саха (Якутия) от 07.09.2016 № 34'
    },

    98:{
        'regName':'Республика Саха (Якутия)',
        'zone':'2 зона',
        'regDescr':'2 зона - территория Республики Саха (Якутия), не включенная в первую и третью зону',
        'inter':16,
        'lawdoc':'Приказ Государственного Комитета по ценовой политике Республики Саха (Якутия) от 07.09.2016 № 34'
    },

    99:{
        'regName':'Республика Саха (Якутия)',
        'zone':'3 зона',
        'regDescr':'3 зона - Абыйский, Аллаиховский, Анабарский, Булунский, Верхнеколымский, Верхоянский, Жиганский, Нижнеколымский,  Кобяйский, Момский, Оймяконский,  Оленекский, Среднеколымский, Усть-Янский, Эвено-Бытантайский районы Республики Саха (Якутия)',
        'inter':16,
        'lawdoc':'Приказ Государственного Комитета по ценовой политике Республики Саха (Якутия) от 07.09.2016 № 34'
    },

    100:{
        'regName':'Приморский край',
        'zone':'1 зона',
        'regDescr':'1 зона - местности за исключением местностей,  приравненных к районам Крайнего Севера',
        'inter':15,
        'lawdoc':'Постановление Администрации Приморского края от 27.02.2010 № 58-па'
    },

    101:{
        'regName':'Приморский край',
        'zone':'2 зона',
        'regDescr':'2 зона - местности,  приравненные к районам Крайнего Севера',
        'inter':15,
        'lawdoc':'Постановление Администрации Приморского края от 27.02.2010 № 58-па'
    },

    102:{
        'regName':'Хабаровский край',
        'zone':'1 зона',
        'regDescr':'1 зона - город Хабаровск, Бикинский, Вяземский, имени Лазо, Хабаровский районы',
        'inter':12,
        'lawdoc':'Постановление Губернатора Хабаровского края  от 26.02.2010 № 32, от 11.08.2011 № 77, постановление Губернатора Хабаровского края от 15.04.2014 № 29 "О внесении изменений в постановление Губернатора Хабаровского края от 26 февраля 2010    № 32", постановление Губернатора Хабаровского края от 15.04.2014 № 29'
    },

    103:{
        'regName':'Хабаровский край',
        'zone':'2 зона',
        'regDescr':'2 зона - город Комсомольск-на-Амуре, Амурский, Ванинский, Верхнебуреинский, Комсомольский, Нанайский, Советско-Гаванский, Солнечный районы',
        'inter':12,
        'lawdoc':'Постановление Губернатора Хабаровского края  от 26.02.2010 № 32, от 11.08.2011 № 77, постановление Губернатора Хабаровского края от 15.04.2014 № 29 "О внесении изменений в постановление Губернатора Хабаровского края от 26 февраля 2010    № 32", постановление Губернатора Хабаровского края от 15.04.2014 № 29'
    },

    104:{
        'regName':'Хабаровский край',
        'zone':'3 зона',
        'regDescr':'3 зона - Аяно-Майский, Николаевский, Охотский, имени Полины Осипенко, Тугуро-Чумиканский, Ульчский районы',
        'inter':18,
        'lawdoc':'Постановление Губернатора Хабаровского края  от 26.02.2010 № 32, от 11.08.2011 № 77, постановление Губернатора Хабаровского края от 15.04.2014 № 29 "О внесении изменений в постановление Губернатора Хабаровского края от 26 февраля 2010    № 32", постановление Губернатора Хабаровского края от 15.04.2014 № 29'
    },

    105:{
        'regName':'Амурская область',
        'zone':'1 зона',
        'regDescr':'1 зона - муниципальные образования Амурской области (за исключением указанных ниже)',
        'inter':20,
        'lawdoc':'Приказ Управления государственного  регулирования цен и тарифов Амурской области от 27.02.2010 № 17-пр/м'
    },

    106:{
        'regName':'Амурская область',
        'zone':'2 зона',
        'regDescr':'2 зона - город Зея, город Тында,  Зейский район, Селемджинский район, Тындинский район',
        'inter':25,
        'lawdoc':'Приказ Управления государственного  регулирования цен и тарифов Амурской области от 27.02.2010 № 17-пр/м'
    },

    107:{
        'regName':'Камчатский край',
        'zone':'1 зона',
        'regDescr':'1 зона - Петропавловск-Камчатский городской округ, Вилючинский городской округ, Елизовский муниципальный район',
        'inter':23,
        'lawdoc':'Постановление Правительства Камчатского края от 27.02.2010 № 98-п, Постановление Правительства Камчатского края от 24.06.2010 № 288-П "О внесении изменений в приложения № 1 и № 2 к постановлению Правительства Камчатского края от 27.02.2010 № 98-П, постановление Правительства Камчатского края от 08.10.2014 № 426-п'
    },

    108:{
        'regName':'Камчатский край',
        'zone':'2 зона',
        'regDescr':'2 зона - Корякский округ',
        'inter':23,
        'lawdoc':'Постановление Правительства Камчатского края от 27.02.2010 № 98-п, Постановление Правительства Камчатского края от 24.06.2010 № 288-П "О внесении изменений в приложения № 1 и № 2 к постановлению Правительства Камчатского края от 27.02.2010 № 98-П, постановление Правительства Камчатского края от 08.10.2014 № 426-п'
    },

    109:{
        'regName':'Камчатский край',
        'zone':'3 зона',
        'regDescr':'3 зона - Мильковский, Усть-Большерецкий, Быстринский, Усть-Камчатский муниципальные районы',
        'inter':23,
        'lawdoc':'Постановление Правительства Камчатского края от 27.02.2010 № 98-п, Постановление Правительства Камчатского края от 24.06.2010 № 288-П "О внесении изменений в приложения № 1 и № 2 к постановлению Правительства Камчатского края от 27.02.2010 № 98-П, постановление Правительства Камчатского края от 08.10.2014 № 426-п'
    },

    110:{
        'regName':'Камчатский край',
        'zone':'4 зона',
        'regDescr':'4 зона - Соболевский, Алеутский муниципальные районы',
        'inter':23,
        'lawdoc':'Постановление Правительства Камчатского края от 27.02.2010 № 98-п, Постановление Правительства Камчатского края от 24.06.2010 № 288-П "О внесении изменений в приложения № 1 и № 2 к постановлению Правительства Камчатского края от 27.02.2010 № 98-П, постановление Правительства Камчатского края от 08.10.2014 № 426-п'
    },

    111:{
        'regName':'Магаданская область',
        'zone':'1 зона',
        'regDescr':'1 зона - город Магадан',
        'inter':10,
        'lawdoc':'Постановление Администрации Магаданской области от 25.02.2010 № 70-па (в редакции постановлений Администрации Магаданской области от 08.04.2010 № 176-па, от 03.06.2010 № 314-па, от 30.12.2010 №765-па, от 02.10.2014 № 812-пп)'
    },

    112:{
        'regName':'Магаданская область',
        'zone':'2 зона',
        'regDescr':'2 зона - муниципальные образования области за исключением МО "город Магадан"',
        'inter':10,
        'lawdoc':'Постановление Администрации Магаданской области от 25.02.2010 № 70-па (в редакции постановлений Администрации Магаданской области от 08.04.2010 № 176-па, от 03.06.2010 № 314-па, от 30.12.2010 №765-па, от 02.10.2014 № 812-пп)'
    },

    113:{
        'regName':'Сахалинская область',
        'zone':'1 зона',
        'regDescr':'1 зона - город Южно-Сахалинск',
        'inter':15,
        'lawdoc':'Постановление Правительства Сахалинской области от 25.02.2010 № 59, от 15.04.2010 № 170 (в ред постановления Правительства Сахалинской области от 07.07.2010 № 327)'
    },

    114:{
        'regName':'Сахалинская область',
        'zone':'2 зона',
        'regDescr':'2 зона - Анивский городской округ, городской округ Долинский, Холмский городской округ, Корсаковский городской округ, Невельский муниципальный район, Томаринский городской округ, Углегорский муниципальный район, Макаровский городской округ, Александров-Сахалинский район,Тымовский городской округ, городской округ Поронайский,  городской округ Смирныховский, городской округ Ногликский, городской округ Охинский',
        'inter':15,
        'lawdoc':'Постановление Правительства Сахалинской области от 25.02.2010 № 59, от 15.04.2010 № 170 (в ред постановления Правительства Сахалинской области от 07.07.2010 № 327)'
    },

    115:{
        'regName':'Сахалинская область',
        'zone':'3 зона',
        'regDescr':'3 зона - муниципальные образования, Северо-Курильский городской округ, Курильский городской округ, Южно-Курильский городской округ',
        'inter':15,
        'lawdoc':'Постановление Правительства Сахалинской области от 25.02.2010 № 59, от 15.04.2010 № 170 (в ред постановления Правительства Сахалинской области от 07.07.2010 № 327)'
    },

    116:{
        'zone':'',
        'regName':'Еврейская автономная область',
        'regDescr':'',
        'inter':14,
        'lawdoc':'Постановление Правительства Еврейской а.о. от 27.02.2010 № 64-пп  (в ред. Постановления правительства ЕАО от 16.07.2012 № 343-пп)'
    },

    117:{
        'regName':'Чукотский автономный округ',
        'zone':'1 зона',
        'regDescr':'1 зона - городской округ Анадырь, муниципальные районы Билибинский, Иультинский, Провиденский, Чаунский, Чукотский муниципальный район, городское поселение Беринговский, сельские поселения Алькатваам, Мейныпильгино, Хатырка',
        'inter':8,
        'lawdoc':'Постановление Правительства Чукотского а.о. от 22.04.2013 № 146.'
    },

    118:{
        'regName':'Чукотский автономный округ',
        'zone':'2 зона',
        'regDescr':'2 зона - Анадырский муниципальный район(кроме городского поселения Беринговский, сельских поселений Алькатваам, Мейныпильгино, Хатырка)',
        'inter':10,
        'lawdoc':'Постановление Правительства Чукотского а.о. от 22.04.2013 № 146.'
    }   
},

    regSubjects: {},
    selectedRegs: {},
    nOfSubjects: 0,
    nOfRegions: 0,
    netError: 0,
    regsLoaded: {},
    subjList: {},
    regListSize: 0,
    currSubj: 0,
    currRegion: 1,


// Populate regSubjects array from net or local hardcoded dataset and render subject map

    initRegData(){

        var initPos = 100,
            sliderGain = 418;

    //  getRegData(); // Перенесено в remoteDataLoad
//!!D
this.netError = 1; // Убрать после создания завершения работы с внутренним массивом данных
//        this.getSubjects(); // В текущей версии не грузим с сервера список субъектов
          this.loadRegList(); // Выбираем набор регионов для текущего субъекта и помещаем в 
                              // selectedRegs
// Used static subject view 
//        this.renderSubjList();
    },

// Render map of subjects into regscroll div

    renderSubjList(){

        // var i, str;

        // $('#regscroll').empty();
        // $('#regscroll').append('<div id="mainmap"><img src="img/map-wind.png" style="position: absolute; z-index:200" usemap="#subjects"></div>');
        // for(i=0;i<=this.nOfSubjects;i++){
        //     $('#mainmap').append('<div class="regmap hidmap" id="regmap' + i + '">'+
        //         '</div>');
        //     $('#subject'+i).bind('mousedown touchstart', subjClick);
        //     $('#subject'+i).hover(subjIn,subjOut);
        // }
    },

// Create array of subjects from fetched data or from static stucture regList

    getSubjects(){

        var i, currReg, cnt, subjLimit, currSubj;

        this.nOfRegions = this.countRegions();
        if(this.netError==0){
            subjLimit = this.subjList.length;
            for(i=0;i<subjLimit;i++){
                    currReg = {};
                    currSubj = this.subjList[i];
                    currReg['regName'] = currSubj['name'];
                    currReg['zone'] = '';
                    currReg['regDescr'] = '';
                    currReg['inter'] = 0;
                    currReg['lawdoc'] = '';
                    this.regSubjects[i] = currReg;
                }
        } else {
            cnt = 0;
            for(i=0;i<this.nOfRegions;i++){
                currReg = this.regList[i];
                if(currReg['inter']==0){
                    this.regSubjects[cnt] = currReg;
                    cnt++;
                }
            }
        }
        this.nOfSubjects = this.countSubjects();  
    },

    loadRegList(){
        var i, j, cnt, currReg, regCnt, tmpReg, dataCnt, rg;

        this.selectedRegs = {};
        regCnt = 0;
        this.nOfRegions = this.countRegions();
        if(this.netError==0){
            for(i=0;i<this.nOfRegions;i++){
                currReg = this.regsLoaded[i];
                if(currReg['subject']==this.currSubj+1){
                    tmpReg = {};
                    tmpReg['n'] = i;
                    tmpReg['regName'] = currReg['name'];
                    tmpReg['zone'] = currReg['zone'];
                    tmpReg['regDescr'] = currReg['description'];
                    tmpReg['inter'] = currReg['inter'];
                    tmpReg['lawdoc'] = currReg['lawdoc'];
                    this.selectedRegs[regCnt] = tmpReg;
                    regCnt++;
                }
            }
        } else {
            cnt = 0, dataCnt = 0;
            for(i=0 ; i<this.nOfRegions ; i++){
                currReg = this.regList[i];
                if(currReg['inter']==0){
                    dataCnt--;
                    if(cnt==this.currSubj){
                        j = i + 1;
                        dataCnt++;
                        currReg = this.regList[j];
                        while((currReg['inter']!=0)&&(j<this.nOfRegions)){
                            tmpReg = {};
                            tmpReg['regName'] = currReg['regName'];
                            tmpReg['zone'] = currReg['zone'];
                            tmpReg['regDescr'] = currReg['regDescr'];
                            tmpReg['inter'] = currReg['inter'];
                            tmpReg['lawdoc'] = currReg['lawdoc'];
                            tmpReg['n'] = dataCnt; 
                            this.selectedRegs[regCnt] = {};
                            for(rg in tmpReg) {
                                if(!tmpReg.hasOwnProperty(rg)) continue;
                                this.selectedRegs[regCnt][rg] = tmpReg[rg];
                            }
                            j++; regCnt++; dataCnt++;
                            if(j>=this.nOfRegions) break;
                            currReg = this.regList[j];
                        }
                        return; 
                    }
                    cnt++;
                }
                dataCnt++;
            }
        }
    },

    renderRegList(){

        var i, str, info, size, frameSize, scrollSize, divSize;

        this.loadRegList();
        size = this.countElements(this.selectedRegs);
        
        // Empty list
//        $('#regscroll').empty();

        for(i=0;i<size;i++){
            str = this.selectedRegs[i].regName;
            info = this.selectedRegs[i].regDescr;

        //Render list element    
            // $('#regscroll').append('<div class="regline" id="regline' + i + '">'+
            //     '<div id="regname'+ i +'" class="regname">' + str + '</div>'+
            //     '<div id="reginfo'+ i +'" class="reginfo">' + info + '</div>'+
            //     '</div>');
        }
    },

    countRegions(){
        if(this.netError==0){
            return this.regsLoaded.length;
        } else {
            return this.countElements(this.regList);
        }
    },

    countSubjects(){
        return this.countElements(this.regSubjects);
    },

    countElements(data){
        var i, cnt;

        cnt = 0;
        for(i in data){
            cnt++;
        }
        return cnt;
    },


	refreshScreen2() {

	    renderOneInfusion();
	    renderCursData();
	    renderScr2Graph();
	},

	formatMil(val) {

	    var num, intPart, intStr, floatPart, txt, intLen, mil, floatStr, fixed;

        num = new Number(val/1000000);
        fixed = num.toFixed(2);
        return fixed;

        // intPart = Math.floor(val/1000000); // int part of millions
        // // intStr = '' + intPart;
        // // if(intPart==0) intLen = 0;
        // // else intLen = intStr.length;
        // // txt = '' + val;


        // txt = '' + val;
        // if(intPart>0) {
        //     intPart = '' + intPart;
        //     intLen = intPart.length;
        // } else {
        //     intLen = 0;
        // }    
        // mil = val;
        // while(mil<1000000) {
        //     mil *= 10;
        //     if(mil<1000000) txt = '0' + txt;
        // }
        // floatPart = txt.slice(intLen); // remainder
        // if(floatPart.length < 2) floatPart += '0';
        // floatStr = floatPart.slice(0,2);
        // return intPart + ',' + floatStr;
	},


refreshAll() {

    refreshScreen1();
    refreshScreen2();
    refreshScreen3();
},

savePriceValues() {

    var cnt, str;

    if(this.priceSet.nivol40!=0) this.nivol40 = this.priceSet.nivol40;
    if(this.priceSet.nivol100!=0) this.nivol100 = this.priceSet.nivol100;
    if(this.priceSet.pembro100!=0) this.pembro100 = this.priceSet.pembro100;
    if(this.priceSet.atezo!=0) this.atezo = this.priceSet.atezo;

    str = JSON.stringify(priceSet);
    localStorage.setItem('immunoPrice', str);
    refreshAll();
},


updatePrice(e) {

    var obj, newVal, currVal, last, point, digs;

    if(e.type=='focusin') {
        this.lastEditValue = e.currentTarget.value;
        return;
    }
    obj = e.target.id;
    newVal = e.currentTarget.value;
    last = newVal.slice(-1);
    if(last==',') {
        newVal = newVal.slice(0,newVal.length-1);
        newVal += '.'; 
        e.currentTarget.value = newVal;
        updateDataSet(obj, newVal);
        return;
    }    
    if(last=='.') {
        updateDataSet(obj, newVal);
        return;
    }    
    // if(newVal=='') {
    //     currVal = 0;
    //     }
    point = newVal.indexOf('.');
    if(point>-1) {
        digs = newVal.length - point;
        if(digs>3) { newVal = newVal.slice(0,point+3); digs = 3 }
        newVal = parseFloat(newVal).toFixed(digs-1);
    } else newVal = parseFloat(newVal);

    // if(isNaN(newVal)){
    //     e.currentTarget.value = lastEditValue;
    //     return;    
    // }
    if(isNaN(newVal) || newVal<1){
        newVal = 0;
    }

    e.currentTarget.value = newVal;
    this.lastEditValue = newVal;
    updateDataSet(obj, newVal);
},


calcOneInfusion() {

    var quant40;
    var nivolLeft, pembroLeft, atezoLeft, overflow, mode;
    var part100, topPart, quant100;

    this.atezoVolume = 1200;

    this.nivolVolume = this.patWeight*this.nivolDose;
    if(this.flFixedMode) this.nivolVolume = 240;
//1
    // nivol100Quant = Math.floor(nivolVolume/100);
    // quant40 = nivolVolume-nivol100Quant*100;
    // nivol40Quant = Math.ceil(quant40/40);
//2
    // nivol100Quant = Math.ceil(nivolVolume/100);
    // overflow = nivol100Quant*100 - nivolVolume;
    // if(overflow>40) { // It's better to use nivol40 + nivol100
    //     nivol100Quant--;
    //     quant40 = nivolVolume-nivol100Quant*100;
    //     nivol40Quant = Math.ceil(quant40/40);
    //     mode = 1;
    // } else { // Using nivol100 only
    //     nivol40Quant = 0;
    //     mode = 2;
    // }


//3
// Отделяем все объемы кратные 200, которые набираем по 100мл

    // quant100 = Math.floor(nivolVolume/200);
    // part100 = quant100*200; // Какой объем можно сразу набрать объемами по 200мл 
    // topPart = nivolVolume - part100; // Остаток, набираемый комбинацией
    // quant100 = quant100*2; // Количество флаконов для набора объема по 200мл
    // topPart = 20*Math.ceil(topPart/20); // Ближайший сверху объем кратный 20-ти
    // mode = 0; // Добираем по 40мл 
    // if(topPart==200) { // Набираем 200мл как 2*100
    //     quant100 += 2;
    //     quant40 = 0;
    //     mode = 1;
    // }
    // else if(topPart==140 || topPart==180 || topPart==100) {// Набираем объем как 100 + n*40
    //     quant40 = topPart - 100;
    //     quant40 = Math.round(quant40/40);
    //     quant100 += 1;
    // } else { // Набираем объем по 40
    //     quant40 = Math.round(topPart/40);
    //     if(quant40==0) mode = 1;
    // }

// 4
// Находим перебором комбинацию с минимальным переполнением по объему

    var top100, top40, cnt100, cnt40, summ, dif, mindif,
        priceSumm, priceCnt;

    quant100 = 0; quant40 = 0;    
    top100 = Math.ceil(this.nivolVolume/100);
    top40 = Math.ceil(this.nivolVolume/40);
    mindif = 100;
    for(cnt100=0 ; cnt100<=top100 ; cnt100++) {
        for(cnt40=0 ; cnt40<=top40 ; cnt40++) {
            summ = cnt100*100 + cnt40*40;
            dif = summ - this.nivolVolume;
            if(dif>=0 && dif<=mindif) {
              mindif = dif;
              quant100 = cnt100;
              quant40 = cnt40;  
            }
        }
    }

// Зафиксируем стоимость инфузии, расчитанной по алгоритму минимального переполнения 
// по объему    

    var v1Price; // Стоимость инфузии по алгоритму без учета цены
    var q100Vol, q40Vol; // Зафиксированные флаконы для первого алгоритма
    v1Price = quant100*this.nivol100 + quant40*this.nivol40;
    q100Vol = quant100; q40Vol = quant40;

// 5
// Находим перебором комбинацию с минимальной стоимостью

    quant100 = 0; quant40 = 0;    
    top100 = Math.ceil(this.nivolVolume/100);
    top40 = Math.ceil(this.nivolVolume/40);
    mindif = 100; priceSumm = -1;
    for(cnt100=0 ; cnt100<=top100 ; cnt100++) {
        for(cnt40=0 ; cnt40<=top40 ; cnt40++) {
            summ = cnt100*100 + cnt40*40;
            dif = summ - this.nivolVolume;
            if(dif>=0) {
                priceCnt = cnt100*this.nivol100 + cnt40*this.nivol40;
                if(priceSumm == -1) priceSumm = priceCnt;
                if(priceSumm >= priceCnt) {
                    priceSumm = priceCnt;
                    quant100 = cnt100;
                    quant40 = cnt40;  
                }
            }
        }
    }

    var v2Price; // Стоимость инфузии по алгоритму без c учетом цены
    var priceDelta; // Разница в ценах при оптимизации по разным алгоритмам

    v2Price = quant100*this.nivol100 + quant40*this.nivol40;

    priceDelta = v1Price - v2Price;
// console.log(v1Price);
// console.log(v2Price);
// console.log('Delta: '+priceDelta);
    
    if(priceDelta >= this.calcTarget) { // Надо считать с учетом цены
//console.log('Price mode!');
        this.nivol100Quant = quant100;
        this.nivol40Quant = quant40;
    } else {
        this.nivol100Quant = q100Vol;
        this.nivol40Quant = q40Vol;
    }


//  Find lowest price of 1ml of nivol
    var ml40, ml100;

    mode = 1;
    ml40 = this.nivol40/40;
    ml100 = this.nivol100/100;
    if(ml40<ml100) mode = 0;    

    this.nivolPrice = this.nivol100Quant*this.nivol100 + this.nivol40Quant*this.nivol40;
    this.nivol40Price = this.nivol40Quant*this.nivol40;
    this.nivol100Price = this.nivol100Quant*this.nivol100;
    nivolLeft = this.nivol40Quant*40 + this.nivol100Quant*100 - this.nivolVolume;
    if(mode==1) {
        this.nivolUtil = nivolLeft/100; this.nivolUtil = this.nivolUtil*this.nivol100;
    } else {
        this.nivolUtil = nivolLeft/40; this.nivolUtil = this.nivolUtil*this.nivol40;
    }
    if(this.flSpecial==0) {
        this.pembroVolume = this.patWeight*this.pembroDose;
        this.pembro100Quant = Math.ceil(this.pembroVolume/100);
        this.pembroPrice = this.pembro100Quant*this.pembro100;
        pembroLeft = this.pembro100Quant*100 - this.pembroVolume;
        this.pembroUtil = pembroLeft/100; this.pembroUtil = this.pembroUtil*this.pembro100;
    } else { // Special mode for pembro (200 mg for infusion)
        this.pembroVolume = this.pembroSpecial;
        this.pembro100Quant = Math.ceil(this.pembroVolume/100);
        this.pembroPrice = this.pembro100Quant*this.pembro100;
        pembroLeft = this.pembro100Quant*100 - this.pembroVolume;
        this.pembroUtil = pembroLeft/100; this.pembroUtil = this.pembroUtil*this.pembro100;
    }
    this.atezoQuant = Math.ceil(this.atezoVolume/1200);
    this.atezoPrice = this.atezoQuant*this.atezo;
    atezoLeft = this.atezoQuant*1200 - this.atezoVolume;
    this.atezoUtil = atezoLeft/1200; this.atezoUtil = this.atezoUtil*this.atezo;

    this.prtNivolVolume = this.nivolVolume;
    this.prtPembroVolume = this.pembroVolume;
    this.prtAtezoVolume = this.atezoVolume;
 },

// ************************************************************************************
// ***************** NEW Functions

calcNivolInfusion(nivolVolume) {

    var quant40;
    var nivolLeft, overflow, mode;
    var part100, topPart, quant100;
// Находим перебором комбинацию с минимальным переполнением по объему

    var top100, top40, cnt100, cnt40, summ, dif, mindif,
        priceSumm, priceCnt;

    quant100 = 0; quant40 = 0;    
    top100 = Math.ceil(nivolVolume/100);
    top40 = Math.ceil(nivolVolume/40);
    mindif = 100;
    for(cnt100=0 ; cnt100<=top100 ; cnt100++) {
        for(cnt40=0 ; cnt40<=top40 ; cnt40++) {
            summ = cnt100*100 + cnt40*40;
            dif = summ - nivolVolume;
            if(dif>=0 && dif<=mindif) {
              mindif = dif;
              quant100 = cnt100;
              quant40 = cnt40;  
            }
        }
    }

// Зафиксируем стоимость инфузии, расчитанной по алгоритму минимального переполнения 
// по объему    

    var v1Price; // Стоимость инфузии по алгоритму без учета цены
    var q100Vol, q40Vol; // Зафиксированные флаконы для первого алгоритма
    v1Price = quant100*this.nivol100 + quant40*this.nivol40;
    q100Vol = quant100; q40Vol = quant40;

// 5
// Находим перебором комбинацию с минимальной стоимостью

    quant100 = 0; quant40 = 0;    
    top100 = Math.ceil(nivolVolume/100);
    top40 = Math.ceil(nivolVolume/40);
    mindif = 100; priceSumm = -1;
    for(cnt100=0 ; cnt100<=top100 ; cnt100++) {
        for(cnt40=0 ; cnt40<=top40 ; cnt40++) {
            summ = cnt100*100 + cnt40*40;
            dif = summ - nivolVolume;
            if(dif>=0) {
                priceCnt = cnt100*this.nivol100 + cnt40*this.nivol40;
                if(priceSumm == -1) priceSumm = priceCnt;
                if(priceSumm >= priceCnt) {
                    priceSumm = priceCnt;
                    quant100 = cnt100;
                    quant40 = cnt40;  
                }
            }
        }
    }

    var v2Price; // Стоимость инфузии по алгоритму без c учетом цены
    var priceDelta; // Разница в ценах при оптимизации по разным алгоритмам

    v2Price = quant100*this.nivol100 + quant40*this.nivol40;

    priceDelta = v1Price - v2Price;

    if(priceDelta >= this.calcTarget) { // Надо считать с учетом цены
        this.nivol100Quant = quant100;
        this.nivol40Quant = quant40;
    } else {
        this.nivol100Quant = q100Vol;
        this.nivol40Quant = q40Vol;
    }

//  Find lowest price of 1ml of nivol
    var ml40, ml100;

    mode = 1;
    ml40 = this.nivol40/40;
    ml100 = this.nivol100/100;
    if(ml40<ml100) mode = 0;    

    this.nivolPrice = this.nivol100Quant*this.nivol100 + this.nivol40Quant*this.nivol40;
    this.nivol40Price = this.nivol40Quant*this.nivol40;
    this.nivol100Price = this.nivol100Quant*this.nivol100;
    nivolLeft = this.nivol40Quant*40 + this.nivol100Quant*100 - this.nivolVolume;
    if(mode==1) {
        this.nivolUtil = nivolLeft/100; this.nivolUtil = this.nivolUtil*this.nivol100;
    } else {
        this.nivolUtil = nivolLeft/40; this.nivolUtil = this.nivolUtil*this.nivol40;
    }
    this.prtNivolVolume = this.nivolVolume;
 },


// ===========================================================

    calcOneDrugInfusion(drugNum, mode, currDose) {

        let dose, units, res = {}, initPrice, flCustomPrice = false,
            drugData = drugList[drugNum];

        if(mode=='m') { // Dose is in mg/kg
            dose = currDose*this.patWeight; // Dose in mg
        }
        if(mode=='s') { // Dose is in mg/m2
            dose = currDose*this.bodyArea; // Dose in mg
        }
        if(mode=='a') { // Dose is in mg
            dose = currDose; 
        }
        units = Math.ceil(dose/drugData.dose); // Number of tablets/flasks
        res.packs = Math.ceil(units/drugData.num); // Number of packs
        initPrice = initList[drugNum].price;
        if(initPrice != drugData.price) flCustomPrice = true;
        res.price = res.packs*drugData.price;
        res.price = this.applyRegData(res.price, flCustomPrice);
        res.dose = dose;
        return res;
    },

//========================================

    applyRegData(price, flCustomPrice) {

        let res = price; 

        if(this.flInterest && !flCustomPrice) {
            res = price*100.0/(100.0+this.vat);
            res = res + res*this.currMult/100.0;
            res = Math.ceil(res + res*this.vat/100.0);
        }
        return res;    
    },

//========================================
// Считаем для текущего метода набор данных для выбранного препарата на весь курс 

    calcCursLine(lineNum, flOptim) {

        let cursLen, drugLen, cnt, res = {}, infusData = {}, cursPacks, singlePacks;
        let prepData = schemas[this.currMethod].prepData[lineNum], dose, mode, currDose, flCustomPrice = false;
        let patsRemain, infusSumm = 0, cycleCnt, compensat, patsOut, prevPatsRemain;
        let groupInfus = 0;
        res.cursPrice = 0; 
        cursLen = prepData.timeLine.length;
        if(this.currWeeks<cursLen) cursLen = this.currWeeks;
        if(prepData.prepNum.length==1) {
            cursPacks = [0];
            singlePacks = [0];
            prevPatsRemain = this.totPats;
            cycleCnt = 0; 

//            if(prepData.prepNum[0]==3 && (this.currIndic==4 || this.currIndic==5)) 
                compensat = 0;
            for(cnt=0 ; cnt<cursLen ; cnt++) {
                if(prepData.timeLine[cnt]==0) continue;
                cycleCnt++;
                infusData = this.calcOneDrugInfusion(
                    prepData.prepNum[0],
                    prepData.mode,
                    prepData.dose
                );
// Разделение стоимости для Ипилимумаба для меланомы и ГЦК
                if(prepData.prepNum[0]==3 && flOptim && (this.currIndic==0 || this.currIndic==1)) { 
                    infusData.price = Math.ceil(infusData.price/2);
                }
// Учет выбытия пациентов при терапии РЛ для всех препаратов метода
                if((this.currIndic==4 || this.currIndic==5) && flOptim) { 
patsRemain = Math.round(this.totPats*this.rlModel[cycleCnt*6]/100.0);// Сколько пациентов пришло на текущий цикл
//infusSumm += patsRemain; // Сколько инфузий выполнено к текущему циклу с учетом выбытия 
infusSumm += Math.round(this.totPats*this.rlModel[cnt]/100.0);; // Сколько инфузий выполнено к текущему циклу с учетом выбытия 
patsOut = prevPatsRemain - patsRemain;
prevPatsRemain = patsRemain;
                } else {
infusSumm += this.totPats; // Сколько инфузий выполнено к текущему циклу 
                }   
// Разделение стоимости для Ипилимумаба для РЛ
                if(prepData.prepNum[0]==3 && (this.currIndic==4 || this.currIndic==5)) { 
                    if(cycleCnt<6) { // Компенсируются выбывшие после первых 4 инфузий
//                        compensat = compensat + patsOut*(cycleCnt-1); // Сколько инфузий выпало к данному циклу     
                        compensat = compensat + patsOut*cycleCnt; // Сколько инфузий выпало к данному циклу     
                    }
                    res.compensat = compensat*infusData.price;
                } else {
                    res.compensat = 0;
                    res.groupPrice = this.totPats*infusData.price;                   
                }
                cursPacks[0] += infusData.packs;
                singlePacks[0] = infusData.packs;
                res.cursPrice += infusData.price;        
                res.singlePrice = infusData.price;
                res.dose = infusData.dose;
            }
res.groupPrice = infusSumm*infusData.price;
//console.log(infusData.price)
if(flOptim) res.groupPrice -= res.compensat;
        } else { // Нужен цикл по комбинации форм с оптимальным подбором дозы
                 // Ставим костыль для Ниволумаба
            cursPacks = [0,0];
            singlePacks = [0,0];
            for(cnt=0 ; cnt<cursLen ; cnt++) {
               if(prepData.timeLine[cnt]==0) continue;
// Учет выбытия пациентов при терапии РЛ для всех препаратов метода
               if((this.currIndic==4 || this.currIndic==5) && flOptim) { 
patsRemain = Math.round(this.totPats*this.rlModel[cnt]/100.0);// Сколько пациентов пришло на текущий цикл
infusSumm += patsRemain; // Сколько инфузий выполнено к текущему циклу с учетом выбытия 
               }  else {
infusSumm += this.totPats; // Сколько инфузий выполнено к текущему циклу 
               }  
                this.nivol100 = drugList[1].price; // Цена флакона нивол-100
                this.nivol40 = drugList[2].price; // Цена флакона нивол-40
                if(this.nivol100 != initList[1].price) flCustomPrice = true;
                if(this.nivol40 != initList[2].price) flCustomPrice = true;
                mode = prepData.mode;
                currDose = prepData.dose;
                if(mode=='m') { // Dose is in mg/kg
                    dose = currDose*this.patWeight; // Dose in mg
                }
                if(mode=='s') { // Dose is in mg/m2
                    dose = currDose*this.bodyArea; // Dose in mg
                }
                if(mode=='a') { // Dose is in mg
                    dose = currDose; 
                }
                this.calcNivolInfusion(dose);
                cursPacks[0] += this.nivol100Quant; // 100
                cursPacks[1] += this.nivol40Quant; // 40
                singlePacks[0] = this.nivol100Quant; // 100
                singlePacks[1] = this.nivol40Quant; // 40
                res.cursPrice += this.nivolPrice;
                res.singlePrice = this.nivolPrice;
                res.dose = dose;
            }
            res.cursPrice = this.applyRegData(res.cursPrice, flCustomPrice);
            res.singlePrice = this.applyRegData(res.singlePrice, flCustomPrice);
//            res.groupPrice = this.totPats*res.cursPrice;                   
res.groupPrice = infusSumm*res.singlePrice;                   
        }
        res.cursPacks = cursPacks;
        res.singlePacks = singlePacks;
        return res;
    },

//========================================

    calcMethodResults(method, flOptim) {

        let methData = schemas[method], currLine;
        let len = methData.prepData.length, lineData = [];

        this.currMethod = method;
        for(let cnt=0 ; cnt<len ; cnt++) {
             currLine = this.calcCursLine(cnt, flOptim); 
             if(currLine.singlePrice) lineData.push(currLine);
        }
        return lineData;
    },

//========================================

    calcGroupResults(method, nOfPats, flOptim) {

        let methData = schemas[method], currLine;
        let len = methData.prepData.length, lineData = [];

        this.currMethod = method;
        for(let cnt=0 ; cnt<len ; cnt++) {
             currLine = this.calcCursLine(cnt, flOptim); 
             currLine.cursPrice *= nOfPats;
             if(currLine.singlePrice) lineData.push(currLine);
        }
        return lineData;
    },

// ****************** /NEW FUNCTIONS

	calcCursData() {

	    this.nivolCurs = Math.floor(this.onePatNivolMultipl*this.nivolPrice);
	    this.nivol40Curs = Math.floor(this.onePatNivolMultipl*this.nivol40Price);
	    this.nivol100Curs = Math.floor(this.onePatNivolMultipl*this.nivol100Price);
        this.pembroCurs = Math.floor(this.onePatPembroMultipl*this.pembroPrice);
        this.atezoCurs = Math.floor(this.onePatAtezoMultipl*this.atezoPrice);
	    this.nivol40CursQuant = Math.ceil(this.nivol40Quant*this.onePatNivolMultipl);
	    this.nivol100CursQuant = Math.ceil(this.nivol100Quant*this.onePatNivolMultipl);
        this.pembroCursQuant = Math.ceil(this.pembro100Quant*this.onePatPembroMultipl);
        this.atezoCursQuant = Math.ceil(this.atezoQuant*this.onePatAtezoMultipl);
	    this.nivolCursUtil = Math.floor(this.nivolUtil*this.onePatNivolMultipl);
        this.pembroCursUtil = Math.floor(this.pembroUtil*this.onePatPembroMultipl);
        this.atezoCursUtil = Math.floor(this.atezoUtil*this.onePatAtezoMultipl);

	},


    calcGroupValues() {

        var cnt;

        this.nivolSumm = 0;
        this.nivolSumm40 = 0;
        this.nivolSumm100 = 0;
        this.pembroSumm = 0;
        this.atezoSumm = 0;
        this.nivol40Summ = 0;
        this.nivol100Summ = 0;
        this.pembro100Summ = 0;
        this.atezo1200Summ = 0;
        for(cnt=0 ; cnt<this.totPats ; cnt++) {
            if(this.calcMode=='aver') this.patWeight = this.patAverWeight;
            else this.patWeight = this.groupData[cnt];
            this.calcOneInfusion();
            this.calcCursData();
            this.nivolSumm += this.nivolCurs;
            this.nivolSumm40 += this.nivol40Curs;
            this.nivolSumm100 += this.nivol100Curs;
            this.pembroSumm += this.pembroCurs;
            this.atezoSumm += this.atezoCurs;
            this.nivol40Summ += this.nivol40CursQuant;
            this.nivol100Summ += this.nivol100CursQuant;
            this.pembro100Summ += this.pembroCursQuant;
            this.atezo1200Summ += this.atezoCursQuant;
        }
    },



	calcPersonalData() {
		this.calcOneInfusion();
		this.calcCursData();
 //       this.calcGroupValues();
	},

    calcDrugPrice(){

                var price;
// !! В объекте priceSet цены уже хранятся с НДС
// выделяем стоимость без НДС: a = an*100/(100+vat)

                if(this.flInterest){
                    if(this.priceSet.nivol40==this.defaultSet.nivol40) {    
                        price = this.priceSet.nivol40*100.0/(100.0+this.vat);
                        price = price + price*this.currMult/100.0;
                        this.nivol40 = price + price*this.vat/100.0;
                    } else this.nivol40 = this.priceSet.nivol40; 
                    if(this.priceSet.nivol100==this.defaultSet.nivol100) {    
                        price = this.priceSet.nivol100*100.0/(100.0+this.vat);
                        price = price + price*this.currMult/100.0;
                        this.nivol100 = price + price*this.vat/100.0;
                    } else this.nivol100 = this.priceSet.nivol100; 
                    if(this.priceSet.pembro100==this.defaultSet.pembro100) {    
                        price = this.priceSet.pembro100*100.0/(100.0+this.vat);
                        price = price + price*this.currMult/100.0;
                        this.pembro100 = price + price*this.vat/100.0;
                    } else this.pembro100 = this.priceSet.pembro100; 

                    price = this.priceSet.atezo*100.0/(100.0+this.vat);
                    price = price + price*this.currMult/100.0;
                    this.atezo = price + price*this.vat/100.0;
//                    this.atezo = this.priceSet.atezo;
                } else {
                    this.nivol40 = this.priceSet.nivol40;
                    this.nivol100 = this.priceSet.nivol100;
                    this.pembro100 = this.priceSet.pembro100;
                    this.atezo = this.priceSet.atezo;
                }
    },

    padDigits(str){

        var res='',s='',i,cnt,n;

        str = '' + str;
        n = str.length;
        for(i=0,cnt=0;i<n;i++){
            s = s + str.slice(-1);
            str = str.slice(0,str.length-1);
            cnt++;
            if(cnt>=3){
                cnt = 0;
                s = s + ' ';
            }
        }
        n = s.length;
        for(i=0;i<n;i++){
            res = res + s.slice(-1);
            s = s.slice(0,s.length-1);
        }
	    return(res);
    },

                padFloat(str){

                var res='',s='',i,cnt,n, ext, point;

                ext = '';
                str = '' + str;
                point = str.indexOf('.');
                if(point>-1) {
                    ext = str.slice(point+1, str.length);
                    str = str.slice(0, point);
                    if(ext.length > 2) ext = ext.slice(0, 1);
                    if(ext.length == 1) ext = ext + '0';
                }
                n = str.length;

                for(i=0,cnt=0;i<n;i++){
                    s = s + str.slice(-1);
                    str = str.slice(0,str.length-1);
                    cnt++;
                    if(cnt>=3){
                        cnt = 0;
                        if((n-i)>=2) s = s + ' ';
                    }
                }
                n = s.length;
                for(i=0;i<n;i++){
                    res = res + s.slice(-1);
                    s = s.slice(0,s.length-1);
                }
                if(ext!='') {
                    res += ',';
                    res += ext;
                }
            return(res);
            }


}

export default Store;