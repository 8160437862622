<template>
    <router-link to="/fo_select" class="reg-selection"><span>Регион:</span> {{ currentRegion }}</router-link>
</template>

<script>
//import store
export default {
  name: 'CurrentSet',
  props:{
    reg:String,
  },
  computed:{
    currentRegion:function(){
      return this.reg;
    }
  },
  methods:{
    isNosologyShowing(){
      return this.$router.history.current["name"] !== "Nosology";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.reg-selection{
    font-variant: small-caps;
    color:#928B85;
    font-weight:700;
    font-size:18px;
    display:inline-block;
    margin:12px 20px 12px 0px;
    text-decoration:none;
}
.reg-selection span{
    color:#F26321;
}
</style>