<template>
<div>
    <div class="menu"
        :class="{ 'locked': !locked }">
        <router-link 
            v-for="(menuitem, index) in items"
            :event="locked ? 'click' : ''"
            :to="menuitem.link"
            :key="index"
            class="menu-item"
            :class="{ 'locked': !locked,  'last':index==(items.length-1)}"
            >
            <p v-html="menuitem.title"></p>
        </router-link>
        <router-link
            :event="locked ? 'click' : ''"
            to="/printing"
            class="settings"
            :class="{ 'locked': !locked }">
            <p><font-awesome-icon icon="print" /></p>
        </router-link>
    </div>
        
    </div>
</template>

<script>
export default {
  name: 'Menu',
  props:{
      items: Array,
      locked: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu{
    display:flex;
}
.menu.locked{
    opacity:0.4;
}
.menu-item, .settings{
    display: flex;  
    margin: 0 3px;
    width: 17%;
    height: 68px;
    padding: 0px 12px;
    background: #928B85;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    text-decoration:none;
    color: #FFFFFF;
}
.last{
    width:20%;
}

.menu-item p, .settings p{
    margin:auto;
    align-self:center;
}
.settings p{
    font-size: 30px;
}
.menu-item.locked, .settings.locked{
    cursor: context-menu;
}
.settings{
    width:56px;
}
.menu-item.router-link-exact-active, .settings.router-link-exact-active{
    background: #F26321;
}
</style>