
const drugStore = {

1: { name: 'Ниволумаб', fullName: 'Ниволумаб 100 мг №1', num: 1, dose: 100, price: 85460},
2: { name: 'Ниволумаб', fullName: 'Ниволумаб 40 мг №1', num: 1, dose: 40, price: 34184},
3: { name: 'Ипилимумаб', fullName: 'Ипилимумаб 50 мг №1', num: 1, dose: 50, price: 204748},
4: { name: 'Паклитаксел', fullName: 'Паклитаксел 100 мг №1', num: 1, dose: 100, price: 2267},
5: { name: 'Карбоплатин', fullName: 'Карбоплатин 150 мг №1', num: 1, dose: 150, price: 713},
6: { name: 'Пеметрексед', fullName: 'Пеметрексед 100 мг №1', num: 1, dose: 100, price: 4959},
7: { name: 'Цисплатин', fullName: 'Цисплатин 50 мг №1', num: 1, dose: 50, price: 135}


// 4: { name: 'Паклитаксел', fullName: 'Паклитаксел 100 мг №1', num: 1, dose: 100, price: 2267.36},
// 5: { name: 'Карбоплатин', fullName: 'Карбоплатин 150 мг №1', num: 1, dose: 150, price: 712.72}
// 4: { name: 'Паклитаксел', fullName: 'Паклитаксел 100 мг №1', num: 1, dose: 100, price: 0},
// 5: { name: 'Карбоплатин', fullName: 'Карбоплатин 150 мг №1', num: 1, dose: 150, price: 0}
}

export default drugStore;