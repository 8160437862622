<template>
      <router-link to="/nosology" class="nos-selection" v-if="isNosologyShowing()"><span>Нозология:</span> {{ nosologyName }}</router-link>
</template>

<script>
//import store
export default {
  name: 'CurrentSet',
  props:{
    nosologyName: String
  },
  methods:{
    isNosologyShowing(){
      return this.$router.history.current["name"] !== "Nosology";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nos-selection{
    font-variant: small-caps;
    text-decoration:none;
    color:#928B85;
    font-weight:700;
    font-size:18px;
    display:inline-block;
    margin:12px 20px 12px 0px;
}
.nos-selection span{
    color:#F26321;
}
</style>